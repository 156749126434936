import React, { useRef, useState } from 'react';

import { ApiPaths, METHODS_TYPES } from '../../../utils';
import { NumericFormat } from 'react-number-format';

export default function CampoServicios({ servicio, showButtons = true, handlerServicioUpdate, nombre, cuposDisponibles, cuposDisponiblesHospedaje, children, className, activo }) {

    // Referencia para contar la cantidad de servicios
    const serviceCounter = useRef(servicio.cantidad ? servicio.cantidad : 0)

    // Maneja el contador de servicios según el método (adicionar o remover)
    const handlerServicioClickCounter = (method) => {


        if (method === METHODS_TYPES.adicionar) {
            serviceCounter.current = serviceCounter.current + 1;
        } else if (method === METHODS_TYPES.remover) {
            serviceCounter.current = serviceCounter.current - 1
        }

        // Actualiza el servicio con el nuevo contador
        handlerServicioUpdate(servicio, method, serviceCounter.current)
    }

    return (
        <div className={`servicio-item ${className ? className : ''} `} style={{opacity: activo ? 1 : 0.5}}>
            <div className='servicio-item_container'>
                <div className='servicio-image'>
                    {/* Muestra la imagen del servicio */}
                    {servicio.guia?.imagen ? <img width="70" height="70" src={ApiPaths.guias + servicio.guia.imagen} alt={`Imagen para el servicio ` + servicio.nombre} /> : <img width="70" height="70" src={ApiPaths.servicios + servicio.imagen} alt={`Imagen para el servicio` + servicio.nombre} />}
                </div>
                <div className='servicio-description'>
                    {/* Muestra el nombre y la descripción del servicio */}
                    {!nombre ?
                        <>
                            <h4 className='nombre'>
                                {servicio.nombre}
                            </h4>
                            {servicio.descripcion !== "" ?
                                <p className='instrucciones'>{servicio.descripcion}</p> :
                                ""
                            }

                        </>
                        : <h4 className='nombre'>
                            {nombre}
                        </h4>

                    }
                    {/* Muestra la tarifa del servicio si es mayor a 0 y si showButtons es verdadero */}
                    {servicio.tarifa > 0 && showButtons && <p className='precio'>
                        <NumericFormat value={servicio.tarifa} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </p>}
                </div>
            </div>
            {activo ?
                <>
                    {showButtons ?
                        <div className='selector'>
                            <div className='selector-btn btn-reduce' style={{ flex: 1, justifyContent: 'center' }}>
                                {/* Botón para reducir la cantidad de servicios */}
                                <button type='button' onClick={() => handlerServicioClickCounter(METHODS_TYPES.remover)} className='botonContadorResumen' disabled={serviceCounter.current <= 0}>-</button>
                            </div>
                            <div className='selector-number'>
                                {serviceCounter.current}
                            </div>
                            <div className='selector-btn btn-plus' style={{ flex: 1 }}>
                                {/* Botón para aumentar la cantidad de servicios */}
                                <button type='button' disabled={cuposDisponibles ? serviceCounter.current >= cuposDisponibles : cuposDisponiblesHospedaje ? cuposDisponiblesHospedaje : false} onClick={() => handlerServicioClickCounter(METHODS_TYPES.adicionar)} className='botonContadorResumen'>+</button>
                            </div>
                        </div>
                    : null}
        
                    {children &&
                        <>
                            {children}
                        </>
        
                    }
                </>
                :
                <>
                    <div>
                            <p style={{justifyItems: 'center', textAlign: 'center'}}>NO DISPONIBLE</p>
                    </div>
                </>
            }
        </div>

    )
}