import React, { useEffect, useState } from "react";

import CampoServicio from "./CampoServicio";
import { ApiPaths } from "../../../utils";
import axios from "axios";
import Select from 'react-select'
import moment from 'moment'

export default function ServiciosGuianza({ titulo, servicios, tipoIngreso, actualizarServiciosGeneralesTotal, serviciosTotal, errors, value, cambiarServiciosTotal }) {

  const [servicesSelected, setServicesSelected] = useState({ ...servicios[0] });
  const [currentGuianzaServicios, setCurrentGuianzaServicios] = useState([]);
  const [guias, setGuias] = useState();
  const [serviciodeGuia, setServiciodeGuia] = useState([])
  const validarZonaPadre = serviciosTotal.tipoIngreso.padre ? parseInt(serviciosTotal.tipoIngreso.padre) : parseInt(serviciosTotal.tipoIngreso.id);
  const [isInvalid, setIsInvalid] = useState(serviciosTotal?.botonHabilitado || false)
  const senderoMultiple = serviciosTotal.tipoIngreso.senderoMultiple === 'true';

  /**
   * Maneja la selección de un guía.
   * @param {Object} guide - El guía seleccionado.
   * @param {number} idx - El índice del servicio.
   * @param {Object} servicio - El servicio actual.
   */
  const handlerSelectedGuide = (guide, idx, servicio) => {
    if (guide.id == "GuiaCodigo") {
      cambiarServiciosTotal({
        ...serviciosTotal,
        botonHabilitado: false
      })

    } else {
      cambiarServiciosTotal({
        ...serviciosTotal,
        botonHabilitado: true
      })
    }

    if (senderoMultiple) {
      const copiaGuias = [...currentGuianzaServicios]
      copiaGuias[idx]['guia'] = { ...guide, ids_tipoingresos: '' };
      setServiciodeGuia(copiaGuias)
      setCurrentGuianzaServicios(copiaGuias)
      actualizarServiciosGeneralesTotal({
        servicioGuia: copiaGuias,
      })
      /* const guias = serviciodeGuia
       guias[idx] = { ...servicesSelected, guia: { ...guide, ids_tipoingresos: '' } };
       setServiciodeGuia(guias)
        setCurrentGuianzaServicios(guias)
        actualizarServiciosGeneralesTotal({
          servicioGuia: guias,
        })*/

    } else {
      const guias = serviciodeGuia
      guias[idx] = { ...servicesSelected, guia: { ...guide, ids_tipoingresos: '' } };
      setServiciodeGuia(guias)
      setCurrentGuianzaServicios(guias)
      actualizarServiciosGeneralesTotal({
        servicioGuia: guias,
      })
    }
  }

  /**
   * Agrega un nuevo servicio de guianza.
   */
  const addGuianzaService = () => {
    let guianza = servicios[0];
    currentGuianzaServicios.push(servicios[0]);
    setCurrentGuianzaServicios(Array.from(currentGuianzaServicios));
  }

  /**
   * Agrega un nuevo servicio de guianza para senderos múltiples.
   * @param {Object} sendero - El sendero seleccionado.
   */
  const addGuianzaServiceMultiple = (sendero) => {
    currentGuianzaServicios.push({ ...servicios[0], id_tipoingreso: sendero.senderoId });
    setCurrentGuianzaServicios(Array.from(currentGuianzaServicios));
  }

  /**
   * Inicializa los servicios de guianza.
   */
  const initGuianzaServices = () => {
    if (senderoMultiple) {
      const guianza = servicios[0];
      const items = []
      serviciosTotal.senderosDias.map((sendero) => {
        items.push({ ...guianza, id_tipoingreso: sendero.senderoId })
      })
      setCurrentGuianzaServicios(items);
    } else {
      let guianza = servicios[0];
      let cantidadSolicitada = Math.ceil(serviciosTotal.entradas / guianza.capacidad);
      let guianzasInicialesArr = Array(cantidadSolicitada).fill(guianza);
      setCurrentGuianzaServicios(guianzasInicialesArr);
    }
  }

  /**
   * Realiza una petición para obtener los datos de los guías.
   */
  const peticionTraeDatos = async () => {
    try {
      const res = await axios.get(ApiPaths.guias + "?tipo_ingreso=" + (serviciosTotal.tipoIngreso.id))
      const response = res.data
      setGuias([
        ...response,
        { nombre: "Guia de otra reserva", id: "GuiaCodigo" }
      ])
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (value) {
      setCurrentGuianzaServicios(value)
      setServiciodeGuia(value)
      peticionTraeDatos();
    } else {
      peticionTraeDatos();
      initGuianzaServices();
    }
  }, []);

  /**
   * Remueve un guía del servicio actual.
   */
  const removerGuia = () => {
    currentGuianzaServicios.pop();
    setCurrentGuianzaServicios(Array.from(currentGuianzaServicios));

    actualizarServiciosGeneralesTotal({
      servicioGuia: serviciodeGuia
    })
  }

  /**
   * Valida el código de reserva ingresado.
   * @param {string} codigoReserva - El código de reserva.
   * @param {number} servicioId - El ID del servicio.
   * @param {number} index - El índice del servicio.
   */
  const validarCodigoReserva = async (codigoReserva, servicioId, index) => {
    try {
      if (codigoReserva.length > 2) {
        const resp = await axios(ApiPaths.reservas, { params: { codigoReserva } });
        setIsInvalid(resp.data.length > 0)
        cambiarServiciosTotal({ ...serviciosTotal, botonHabilitado: resp.data.length > 0 })
        if (resp.data.length > 0) {

          const guias = serviciodeGuia
          guias[index]['guia']['codReserva'] = codigoReserva
          guias['tarifa'] = "0"
          setServiciodeGuia(guias)
          setCurrentGuianzaServicios(guias)
          actualizarServiciosGeneralesTotal({
            servicioGuia: guias,
          })
        }

      } else {
        setIsInvalid(false)
        cambiarServiciosTotal({ ...serviciosTotal, botonHabilitado: false })
      }
    } catch (error) {
      console.log(error)
    }

  }

  const consultaFechasDisponibles = (servicio) => {
    const { fecha_deshabitado_ini, fecha_deshabitado_fin } = servicio;
    const fechaActual = moment().startOf('day'); // Fecha actual (sin horas)

    // Si ambas fechas son null, el servicio está disponible
    if (!fecha_deshabitado_ini && !fecha_deshabitado_fin) {
      return true;
    }

    // Si ambas fechas están definidas
    if (fecha_deshabitado_ini && fecha_deshabitado_fin) {
      const fechaInicio = moment(fecha_deshabitado_ini).startOf('day');
      const fechaFin = moment(fecha_deshabitado_fin).startOf('day');

      // Si la fecha actual está entre (o es igual a) fechaInicio y fechaFin, no mostrar
      if (fechaActual.isSameOrAfter(fechaInicio) && fechaActual.isSameOrBefore(fechaFin)) {
        return false; // No mostrar el servicio
      }
    }

    // Si llegamos aquí, el servicio está disponible
    return true;
  };

  return (
    <div className={`CampoServicios row ${errors.servicioGuia && 'is-invalid'}`}>
      <h4 className='col-12 HeaderTablaServicios'>{titulo} para ({serviciosTotal.entradas}) visitantes</h4>
      {validarZonaPadre == 2 && <p className="servicios-guianza-nota">PUEDES SELECCIONAR UN GUIA QUE ACOMPAÑE TU GRUPO, PERO NO LO EXIME DE PAGAR LOS SERVICIOS UTON.</p>}
      {senderoMultiple ?
        serviciosTotal.senderosDias.map((sendero, index) => {
          const validarCantidadSendero = currentGuianzaServicios.filter((servicio) => servicio.id_tipoingreso == sendero.senderoId).length;

          if (sendero.sendero) {
            return (
              <div className="container-guia">
                <h6 className="text-center font-weight-bold">{sendero.nombre} - {sendero.sendero.nombre}</h6>
                <div className="d-flex  " key={index}>
                  {currentGuianzaServicios.map((servicio, idx) => {
                    if (servicio.id_tipoingreso == sendero.senderoId) {
                      return (
                        <ServiciosGuianzaMultiple key={idx} idx={idx} servicio={servicio} sendero={sendero} handlerSelectedGuide={handlerSelectedGuide} addGuianzaService={addGuianzaServiceMultiple} currentGuianzaServicios={currentGuianzaServicios} isInvalid={isInvalid} removerGuia={removerGuia} validarCodigoReserva={validarCodigoReserva} validarCantidadSendero={validarCantidadSendero} activo={consultaFechasDisponibles(servicio)}/>
                      )
                    }
                  })}
                </div>
              </div>
            )
          }
        })
        :
        <>
          {currentGuianzaServicios.map((servicio, idx) =>

            <div className='col-4 px-2 ' key={idx}>
              <CampoServicio servicio={servicio} showButtons={false} activo={consultaFechasDisponibles(servicio)}>
                <div style={{ marginTop: "0px", paddingTop: "5px" }}>
                  {guias &&
                    <Select
                      options={
                        guias.map((guia, index) =>
                          ({ label: guia.nombre, value: guia.id, ...guia })
                        )}
                      placeholder="Nombre guia"
                      defaultValue={servicio.guia}
                      onChange={(e) => handlerSelectedGuide(e, idx)}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}     //important
                    />}

                </div>

                {servicio?.guia?.id == "GuiaCodigo" && (
                  <div style={{ marginTop: "15px" }} >
                    <input defaultValue={servicio?.guia?.codReserva} required onChange={(e) => validarCodigoReserva(e.target.value, servicio.id, idx)} className={`form-control mt-2 ${isInvalid ? 'is-valid' : 'is-invalid'}`} type="text" placeholder="Cod reserva" pattern={!isInvalid} />
                  </div>
                )
                }
              </CampoServicio>
            </div>

          )
          }

          <div className=" col-1 d-flex flex-column justify-content-center">
            {currentGuianzaServicios.length > 1 && <button className="addGuianzaBtn" type="button" onClick={removerGuia}>-</button>}
            <button className="addGuianzaBtn" type="button" onClick={addGuianzaService}>+</button>
          </div>

        </>}


    </div >
  )
}

/**
 * Componente para manejar servicios de guianza múltiples.
 * @param {Object} props - Las propiedades del componente.
 */
const ServiciosGuianzaMultiple = ({ idx, servicio, handlerSelectedGuide, validarCantidadSendero, currentGuianzaServicios, removerGuia, addGuianzaService, sendero, isInvalid, validarCodigoReserva, activo}) => {
  const [guias, setGuias] = useState();

  useEffect(() => {

    const peticionTraeDatos = async () => {
      try {
        const res = await axios.get(ApiPaths.guias + "?tipo_ingreso=" + sendero.senderoId)
        const response = res.data

        setGuias([
          ...response,
          { nombre: "Guia de otra reserva", id: "GuiaCodigo" }
        ])
      } catch (e) {
        console.error(e)
      }
    }
    peticionTraeDatos();

  }, [])

  return (
    <>
      <div className='col-4 px-2 ' key={idx}>
        <CampoServicio servicio={servicio} showButtons={false} activo={activo}>
          <div style={{ marginTop: "0px", paddingTop: "5px" }}>
            {guias &&
              <Select
                options={
                  guias.map((guia, index) =>
                    ({ label: guia.nombre, value: guia.id, ...guia })
                  )}
                placeholder="Nombre guia"
                defaultValue={servicio.guia}
                onChange={(e) => handlerSelectedGuide(e, idx, servicio)}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}     //important
              />}

          </div>
          {servicio?.guia?.id == "GuiaCodigo" && (
            <div style={{ marginTop: "15px" }} >
              <input defaultValue={servicio?.guia?.codReserva} required onChange={(e) => validarCodigoReserva(e.target.value, servicio.id, idx)} className={`form-control mt-2 ${isInvalid ? 'is-valid' : 'is-invalid'}`} type="text" placeholder="Cod reserva" pattern={!isInvalid} />
            </div>
          )
          }
        </CampoServicio>
      </div>
      <div className=" col-1 d-flex flex-column justify-content-center">
        {validarCantidadSendero > 1 && <button className="addGuianzaBtn" type="button" onClick={removerGuia}>-</button>}
        <button className="addGuianzaBtn" type="button" onClick={() => addGuianzaService(sendero)}>+</button>
      </div>

    </>
  )
}