import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'
import { Form } from 'react-bootstrap';


const SubirEditServicio = ({ baseUrl, data, setData, setFuncion, urlIngresos, registroSelect, TituloBtn, funcion }) => {

	// Estado inicial del registro
	const [registro, setRegistro] = useState(funcion === 'editar' ? registroSelect : { activo: true, imagePrev: fotoVacia })
	const [tiposIngresos] = useTraeDatos(urlIngresos)
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)

	// Estados para las alertas de disponibilidad
	const [agotado, setAgotado] = useState(funcion === 'editar' && registroSelect.alertas ? JSON.parse(registroSelect.alertas)[0] : { nombre: 'Sin Cupo', rangofin: 0 })
	const [pocasExis, setPocasExis] = useState(funcion === 'editar' && registroSelect.alertas ? JSON.parse(registroSelect.alertas)[1] : { nombre: 'Con pocos Cupos', rangoini: 0, rangofin: 0 })
	const [conExist, setconExist] = useState(funcion === 'editar' && registroSelect.alertas ? JSON.parse(registroSelect.alertas)[2] : { nombre: 'Con Disponibilidad', rangoini: 0, rangofin: 0 })
	let AlertaFull = [agotado, pocasExis, conExist]
	const [selectedDateIni, setSelectedDateIni] = useState('');
	const [selectedDateFin, setSelectedDateFin] = useState('');


	const peticionTraeDatos = async () => {
        try {
			const resp = await axios.get(baseUrl)
			setData(resp.data)
        } catch (error) {
			console.log('error', error)
        }
    }

	useEffect(() =>{
		if (funcion === 'editar'){
			if (registroSelect.fecha_deshabitado_ini === null){
				setSelectedDateIni(null)
				setSelectedDateFin(null)
			}else{
				setSelectedDateIni(moment(registroSelect.fecha_deshabitado_ini).format("YYYY-MM-DD"))
				setSelectedDateFin(moment(registroSelect.fecha_deshabitado_fin).format("YYYY-MM-DD"))
			}
		}
		
	},[funcion])
	

	// Función para manejar la creación de un nuevo registro
	const peticionPost = async (e) => {
		e.preventDefault();
		try {
			const fecha = moment(new Date()).format("YYYY-MM-DD-HH-mm-ss");

			let { nombre, tipo, imagen, tarifa, tipoingreso, descripcion, capacidad } = registro

			let formato = imagen.type.replace('image/', '.');
			let RutaServ = 'images/'

			let f = new FormData()
			let NombreIma = RutaServ + fecha + formato

			// Agregar datos al FormData
			f.append('nombre', nombre)
			f.append('tipo', tipo)
			f.append('tarifa', tarifa)
			f.append('id_tipoingreso', traeIdNombre(tiposIngresos, tipoingreso))
			f.append('descripcion', descripcion)
			f.append('imagen', imagen)
			f.append('nombre_imagen', NombreIma)
			f.append('tipoingreso', tipoingreso)
			f.append('alertas', JSON.stringify(AlertaFull))
			f.append('capacidad', capacidad)
			f.append('fecha_deshabitado_ini', selectedDateIni !== '' ? moment(selectedDateIni).format("YYYY-MM-DD") : 'vacio')
			f.append('fecha_deshabitado_fin', selectedDateFin !== '' ? moment(selectedDateFin).format("YYYY-MM-DD"): 'vacio')
			f.append('METHOD', 'POST')
			const response = await axios.post(baseUrl, f)

			peticionTraeDatos()
			setRegistro({ activo: false })
			errores('Limpio', setError)
			setFuncion('ver')

		} catch (error) {
			console.log(error)
		}



	}

	// Función para manejar la actualización de un registro existente
	const ProcedimientoPut = async () => {

		try {
			let { id, nombre, tipo, imagen, tarifa, tipoingreso, descripcion, imagenNueva, nombreImaNueva, capacidad } = registro

			let f = new FormData()
			f.append('id', id)
			f.append('nombre', nombre)
			f.append('tipo', tipo)
			f.append('tarifa', tarifa)
			f.append('id_tipoingreso', traeIdNombre(tiposIngresos, tipoingreso))
			f.append('descripcion', descripcion)
			f.append('tipoingreso', tipoingreso)
			f.append('alertas', JSON.stringify(AlertaFull))
			f.append('capacidad', capacidad)
			f.append('fecha_deshabitado_ini', selectedDateIni !== null ? moment(selectedDateIni).format("YYYY-MM-DD") : 'vacio')
			f.append('fecha_deshabitado_fin', selectedDateFin !== null ? moment(selectedDateFin).format("YYYY-MM-DD"): 'vacio')
			f.append('METHOD', 'PUT')
			f.append('imagen', imagenNueva !== undefined ? imagenNueva : imagen)
			f.append('nombre_imagen', nombreImaNueva)
			if (imagenNueva !== undefined) { f.append('nombre_imagen_vieja', imagenOld) }

			const response = await axios.post(baseUrl, f)

			peticionTraeDatos()
			errores('Limpio', setError)
			setFuncion('ver')
		} catch (error) {
			console.log('muestra erro: ' + error);
		}
	}

	// Función para manejar el envío del formulario de actualización
	const peticionPut = (e) => {
		e.preventDefault();
		let { nombre, tipo, tarifa, tipoingreso, descripcion, imagenNueva } = registro

		if (imagenNueva !== undefined) {
			//Cuando Hay una imagen nueva
			let formatosPermitidos = ['.png', '.jpeg', '.jpg', '.JPEG']
			//	console.log('imagenNueva', imagenNueva)
			let formato = typeof (imagenNueva) !== "string" ? imagenNueva.type.replace('image/', '.') : null;
			//Pregunta si el formato y peso son correctos
			if (imagenNueva !== '' && imagenNueva.size < 2000000 && formatosPermitidos.includes(formato)) {
				ProcedimientoPut()
			} else { errores('ImagenIncorrecta', setError) }


		} else errores('CampoVacio', setError)
		ProcedimientoPut()
	}

	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					<div><hr />
						<Form onSubmit={funcion === 'editar' ? peticionPut : peticionPost}>


							<div className='row'>
								<div className='col-md-9'>
									<MDBInput
										label="Nombre del Servicio"
										name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
										value={registro.nombre}
										onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
									/>

									<div className="input-group">
										<div className="input-group-prepend">
										</div>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input"
												id="inputGroupFile01"
												aria-describedby="inputGroupFileAddon01"
												name="imagen"
												accept="image/png, image/gif, image/jpeg"
												onChange={recibeImagen(registro, setRegistro, funcion)}
											/>
											<label className="custom-file-label">
												Seleccionar Foto (400px x 400px)
											</label>
										</div>
									</div>

									<div className="row">
										<div className='col-md-6'>

											<MDBInput
												label="Tarifa"
												name="descripcion"
												required
												className={error.campoClave ? "form-control is-invalid" : null} outline
												value={registro.tarifa}
												onChange={e => setRegistro({ ...registro, tarifa: e.target.value })}
											/>
										</div>
										<div className='col-md-6'>
											<br></br>
											<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
												value={registro.tipo}
												required
												onChange={e => setRegistro({ ...registro, tipo: e.target.value })}>
												<option value={""}>Selecciona un tipo de Servicio</option>
												<option value={"Vehiculo"}>Vehículo</option>
												<option value={"Alimentacion"}>Alimentación</option>
												<option value={"Hospedaje"}>Hospedaje</option>
												<option value={"Alquiler"}>Alquiler</option>
												<option value={"Guianza"}>Guianza</option>
												<option value={"VehiculoCodigo"}>VehiculoCodigo</option>
											</select>
										</div>
									</div>

									<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
										value={registro.tipoingreso}
										required
										onChange={e => setRegistro({ ...registro, tipoingreso: e.target.value })}>
										<option value={""}>Selecciona Disponibilidad</option>
										{tiposIngresos.map((item, index) => {
											if (item.tipo === "Ingreso") {
												return (<option key={index} value={item.nombre}>{item.nombre}</option>)
											}
										})}
									</select>

								</div>
								<div className="col-md-3">
									<div className="FotoPrev">
										{funcion === 'editar' ?
											<img alt="imagen" src={
												registro.imagePrev === registro.imagen ?
													baseUrl + "/" + registro.imagePrev
													: registro.imagePrev
											} />
											:
											<img src={registro.imagePrev} />
										}
									</div>
								</div>
							</div>

							<MDBInput label="Descripción" name="descripcion" required className={error.campoClave ? "form-control is-invalid" : null} outline
								value={registro.descripcion}
								onChange={e => setRegistro({ ...registro, descripcion: e.target.value })}
							/>

							<MDBInput label="capacidad" name="capacidad" required className={error.campoClave ? "form-control is-invalid" : null} outline
								value={registro.capacidad}
								onChange={e => setRegistro({ ...registro, capacidad: e.target.value })}
							/>


							{registro?.tipo == "Hospedaje" && <>
								<h5>Alertas:</h5>
								<div className="row">

									<div className="col-md-4"> <br></br><p className="estados Rojo">Sin Cupo</p></div>
									<div className="col-md-8"><MDBInput label="Rango Menor a " outline
										value={agotado.rangofin}
										required
										onChange={e => setAgotado({ ...agotado, rangofin: e.target.value })} />
									</div>


									<div className="col-md-4"> <br></br><p className="estados Amarillo">Con pocos Cupos</p></div>
									<div className="col-md-4"><MDBInput label="Rango Inicial " outline
										value={pocasExis.rangoini}
										required
										onChange={e => setPocasExis({ ...pocasExis, rangoini: e.target.value })} />
									</div>
									<div className="col-md-4"><MDBInput label="Rango Final" outline
										value={pocasExis.rangofin}
										onChange={e => setPocasExis({ ...pocasExis, rangofin: e.target.value })} />
									</div>


									<div className="col-md-4"> <br></br><p className="estados Verde">Con Disponibilidad</p></div>
									<div className="col-md-8"><MDBInput label="Rango Mayor a" outline
										value={conExist.rangoini}
										required
										onChange={e => setconExist({ ...conExist, rangoini: e.target.value })} />
									</div>


								</div>

								<br />


							</>}



<p><b>Deshabilitar servicio por fechas:</b></p>
							<div style={{ display: "flex", flexDirection: "row", gap: "10px", width: "400px", marginBottom: 30}}>

								
								<div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "200px" }}>
									<label htmlFor="datePicker" style={{marginBottom: 0 }}>
										Fecha Inicial:
									</label>
									<input
										type="date"
										id="datePicker"
										name="datePicker"
										value={selectedDateIni}
										onChange={e => setSelectedDateIni(e.target.value)}
										style={{
										padding: "8px",
										fontSize: "16px",
										borderRadius: "4px",
										border: "1px solid #ccc",
										width: "100%",
										}}
									/>
								</div>

								<div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "200px" }}>
								<label htmlFor="datePicker" style={{ marginBottom: 0 }}>
									Fecha Final:
								</label>
								<input
									type="date"
									id="datePicker"
									name="datePicker"
									value={selectedDateFin}
									onChange={e => setSelectedDateFin(e.target.value)}
									style={{
									padding: "8px",
									fontSize: "16px",
									borderRadius: "4px",
									border: "1px solid #ccc",
									width: "100%",
									}}
								/>
								</div>
							</div>


							<div className="col text-center">
								<button type='submit' className="btn boton_estandar">{TituloBtn} </button>
							</div>
						</Form>
					</div>

				</div>
			</div>
		</div>
	)
}

export default SubirEditServicio